import { connect } from 'react-redux'

import { Capabilities } from 'static/constants'

import PrimaryNavigation from 'components/content_desk_new/primary_navigation/'

import {
  openNewContent as openContent,
  openNewContentDialog as openContentDialog,
  openCampaignForm
} from 'actions/content_desk'

import { getCDeskContentIsLoading, hasCapability } from 'selectors'

const mapStateToProps = state => ({
  hasLinkedIn: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_LINKED_IN),
  hasFacebook: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_FACEBOOK),
  isContentLoading: getCDeskContentIsLoading(state)
})

export default connect(
  mapStateToProps,
  {
    openContent,
    openContentDialog,
    openCampaignForm
  }
)(PrimaryNavigation)
