import React from 'react'
import { List } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button
} from '@mui/material'

import { IconButton } from 'containers/themed'
import Recipients from 'containers/content_desk_new/Recipients'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'

const useStyles = makeStyles()({
  title: {
    color: '#404040'
  },
  container: {
    marginBottom: '1rem',
    textAlign: 'center'
  }
})

const RecipientsBrowser = ({
  recipients,
  open,
  query,
  onClose,
  onQueryChange,
  setContentFormData
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleClearAll = () => setContentFormData({ key: 'recipients', value: List() })

  const handleChange = e => {
    onQueryChange(e.target.value)
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle className={classes.title}>
        {i18n.get('all_recipients')} ({recipients.size})
      </DialogTitle>
      <IconButton
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div
          className={classes.container}
        >
          <Grid
            container
            spacing={2}
          >

            <Grid
              item
              xs={12}
              md={8}
            >
              <TextField
                fullWidth
                label={i18n.get('search_recipients_label')}
                variant="outlined"
                value={query}
                name="recipientQuery"
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Button
                variant="outlined"
                onClick={handleClearAll}
                startIcon={<DeleteIcon />}
                sx={{
                  height: '100%'
                }}
              >

                {i18n.get('clear_all')}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Recipients />
      </DialogContent>
    </Dialog>
  )
}

RecipientsBrowser.propTypes = {
  recipients: PropTypes.instanceOf(List).isRequired,
  open: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  setContentFormData: PropTypes.func.isRequired
}

export default RecipientsBrowser
