import { parse } from 'query-string-es5'
import { getFirstValidRoute } from 'utils/routing'
import { getCapabilities } from './user'
import { getInitialRoute } from './config'

export const getCurrentPath = state => {
  if (state.router && state.router.location && state.router.location.pathname) {
    return state.router.location.pathname.replace(/\/$/, '')
  }

  return ''
}

export const isShoppingCart = state => getCurrentPath(state).match(/shopping/) !== null
export const isSavedSearchesOverview = state => getCurrentPath(state).match(/saved_searches/) !== null
export const isMediaReviewDetail = state => getCurrentPath(state).match(/media_reviews\/\d+/) !== null
export const isProfileMonitoring = state => getCurrentPath(state).match(/profile_monitoring/) !== null
export const isDashboard = state => getCurrentPath(state).match(/dashboard/) !== null
export const isSelectedDashboard = state => getCurrentPath(state).match(/dashboard\/.*?/) !== null
export const isNewsBoard = state => getCurrentPath(state).match(/\/news(\/|$)/) !== null
export const isSearchPool = state => getCurrentPath(state).match(/\/(search_pool|media_monitoring)(\/|$)/) !== null
export const isDarknetQueryManager = state => getCurrentPath(state).match(/\/dashboard\/query_manager(\/|$)/) !== null
export const isExternalChart = state => getCurrentPath(state).match(/\/external_chart(\/|$)/) !== null
export const isSavedChart = state => getCurrentPath(state).match(/\/charts\/.*?\/(embed|image).*/) !== null
export const getMainModule = state => getCurrentPath(state).split('/')[2]
export const isAdministrationSubmodule = state => getCurrentPath(state).match(/\/administration(\/|$)/) !== null
export const isContentDeskSubmodule = state => getCurrentPath(state)
  .match(/.*\/content_desk\/(content_management|template_library)$/) !== null
export const isContactManagementSubmodule = state => getCurrentPath(state).match(/\/contact_management(\/|$)/) !== null
export const isInfoboard = state => getCurrentPath(state).match(/\/infoboard\/.*?/) !== null
export const getSearchFromUrl = state => {
  if (state.router && state.router.location) {
    return state.router.location.search
  }

  return ''
}
export const getQueryParamsFromUrl = state => {
  if (state.router && state.router.location && state.router.location.search) {
    return parse(state.router.location.search)
  }

  return {}
}

export const getRedirectParam = state => getQueryParamsFromUrl(state).redirect

export const getFallbackRoute = state => {
  const redirect = getRedirectParam(state)

  if (redirect) {
    return redirect
  }

  const initialRoute = getInitialRoute(state)

  if (initialRoute) {
    return initialRoute
  }

  const capabilities = getCapabilities(state)

  return getFirstValidRoute(capabilities)
}
