import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { Grid } from '@mui/material'

import StatisticsCard from 'components/content_desk_new/kpi_statistics/statistics_card'

import { toSnakeCase } from 'utils/string'

const statisticsConfig = {
  email: [
    'openRate',
    'clickRate',
    'deliverabilityRate',
    'mailsSent'
  ],
  socialMedia: [
    'pageViews',
    'pageFollowersGain',
    'postEngagements',
    'postImpressions'
  ],
  combined: [
    'openRate',
    'clickRate',
    'postEngagements',
    'pageFollowersGain'
  ]
}

export default function KpiStatistics({
  hasSocialMedia,
  kpiStatistics,
  view,
  timePeriod,
  loadingKpiStatistics
}) {
  const getMetricsForView = tabView => {
    if (hasSocialMedia) {
      if (tabView === 'email') {
        return statisticsConfig.email
      }

      if (tabView === 'social_media') {
        return statisticsConfig.socialMedia
      }

      return statisticsConfig.combined
    }

    return statisticsConfig.email
  }

  const buildKpiCards = (metrics, statistics) => (
    metrics.map(metric => {
      const emailMetric = statistics.getIn(['email', metric])
      const socialMediaMetric = statistics.getIn(['socialMedia', metric])
      const metricData = emailMetric || socialMediaMetric || Map()

      return (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={metric}
        >
          <StatisticsCard
            title={`ccd_kpi_${toSnakeCase(metric)}`}
            info={`ccd_kpi_info_${toSnakeCase(metric)}`}
            value={metricData.get('current')}
            difference={metricData.get('difference')}
            trend={metricData.get('trend')}
            type={metricData.get('type')}
            timePeriod={timePeriod}
            loadingKpiStatistics={loadingKpiStatistics}
          />
        </Grid>
      )
    })
  )

  const metrics = getMetricsForView(view)
  const kpiCards = buildKpiCards(metrics, kpiStatistics)

  return (
    <Grid
      container
      columnSpacing={2}
      rowGap={2}
    >
      {kpiCards}
    </Grid>
  )
}

KpiStatistics.propTypes = {
  hasSocialMedia: PropTypes.bool.isRequired,
  kpiStatistics: PropTypes.instanceOf(Map).isRequired,
  view: PropTypes.string.isRequired,
  timePeriod: PropTypes.string.isRequired,
  loadingKpiStatistics: PropTypes.bool.isRequired
}
