import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import moment from 'moment'

import { makeStyles } from 'tss-react/mui'
import { lighten } from '@mui/material/styles'
import {
  AppBar,
  Toolbar as MuiToolbar,
  Tooltip,
  Typography
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import PublishConfirmDialog from 'components/content_desk_new/contents/content_edit_dialog/publish_confirm_dialog'

import ContentApprovalStatusSelect from 'containers/content_desk_new/contents/content_edit_dialog/ContentApprovalStatusSelect'

import { Button } from 'containers/themed'

import { isEditorDesignBlank } from 'utils/content_desk'

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  button: {
    marginLeft: '1em'
  },
  statusButton: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  footerToolbar: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionsContainer: {
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  scheduleIcon: {
    marginRight: '0.5em'
  }
}))

export default function Toolbar({
  contentFormData,
  isLoading,
  isPublishable,
  onChange,
  onSave,
  onTestMail,
  openScheduleDialog,
  setContentIsPublishable
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [publishConfDialogOpen, setPublishConfDialogOpen] = useState(false)
  const title = contentFormData.get('title')
  const isMailContent = contentFormData.getIn(['type', 'value']) === 1
  const senderMail = contentFormData.get('senderMail')
  const subject = contentFormData.get('subject')
  const recipients = contentFormData.get('recipients')
  const status = contentFormData.get('status') || contentFormData.get('approvalStatus')
  const plannedFor = contentFormData.get('plannedFor')
  const scheduledFor = contentFormData.get('scheduledFor')
  const editorDesign = contentFormData.get('editorDesign')

  useEffect(() => {
    let isValid = true

    if (isMailContent) {
      if (contentFormData.get('isDirty')) {
        isValid = false
      }

      if (senderMail === null || senderMail === '') {
        isValid = false
      }

      if (subject === null || subject === '') {
        isValid = false
      }

      if (isEditorDesignBlank(editorDesign)) {
        isValid = false
      }

      if (recipients === null || recipients.isEmpty()) {
        isValid = false
      }
    }

    setContentIsPublishable(isValid)
  }, [contentFormData])

  const onPublishNow = () => {
    setPublishConfDialogOpen(true)
  }

  const handleSave = () => onSave({ closeEditContentDialog: false })

  const sendTestMailValid = () => {
    let isValid = true

    if (isMailContent) {
      if (contentFormData.get('isDirty')) {
        isValid = false
      }

      if (senderMail === null || senderMail === '') {
        isValid = false
      }

      if (subject === null || subject === '') {
        isValid = false
      }

      if (isEditorDesignBlank(editorDesign)) {
        isValid = false
      }
    }

    return isValid
  }

  const mailTooltipTitle = type => {
    if (isMailContent) {
      if (senderMail === null || senderMail === '') {
        return (
          <div>{i18n.get('email_no_sender_mail')}</div>
        )
      }

      if (subject === null || subject === '') {
        return (
          <div>{i18n.get('email_no_subject')}</div>
        )
      }

      if (type === 'send_now' && (recipients === null || recipients.isEmpty())) {
        return (
          <div>{i18n.get('email_no_recipients')}</div>
        )
      }

      if (isEditorDesignBlank(editorDesign)) {
        return (
          <div>{i18n.get('email_no_content')}</div>
        )
      }

      if (contentFormData.get('isDirty')) {
        return (
          <div>{i18n.get('email_content_dirty')}</div>
        )
      }
    }

    return ''
  }

  const saveEmailIsValid = () => {
    let isValid = true

    if (isMailContent) {
      if (title === '') {
        isValid = false
      }

      if (scheduledFor !== null && scheduledFor !== '') {
        isValid = isPublishable || contentFormData.get('isDirty')
      }
    }

    return isValid
  }

  const saveMailTooltipTitle = () => {
    if (isMailContent) {
      if (title === '') {
        return (
          <div>{i18n.get('title')} {i18n.get('is_required')}</div>
        )
      }

      if (scheduledFor !== null && scheduledFor !== '') {
        if (!isPublishable) {
          return mailTooltipTitle('send_now')
        }
      }
    }

    return ''
  }

  const renderPublishConfirmDialog = () => (
    <PublishConfirmDialog
      open={publishConfDialogOpen}
      status={status}
      onChange={onChange}
      onClose={() => setPublishConfDialogOpen(false)}
      onSave={onSave}
    />
  )

  const renderTestMailButton = () => {
    if (!isMailContent) {
      return null
    }

    return (
      <Tooltip
        title={mailTooltipTitle('test')}
        placement="top"
        arrow
      >
        <Typography
          variant="h6"
          component="div"
        >
          <Button
            variant="outlined"
            color="primary"
            disabled={isLoading || !sendTestMailValid()}
            onClick={() => onTestMail()}
            autoFocus
          >
            {i18n.get('send_test_email')}
          </Button>
        </Typography>
      </Tooltip>
    )
  }

  const renderPublishNowButton = () => {
    if (isMailContent) {
      return (
        <Tooltip
          title={mailTooltipTitle('send_now')}
          placement="top"
          arrow
        >
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => onPublishNow()}
              className={classes.button}
              disabled={isLoading || !saveEmailIsValid() || !isPublishable}
            >
              {i18n.get('send_now')}
            </Button>
          </Typography>
        </Tooltip>
      )
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => onPublishNow()}
        className={classes.button}
        disabled={isLoading || !saveEmailIsValid() || !isPublishable}
      >
        {i18n.get('publish_now')}
      </Button>
    )
  }

  return (
    <>
      {renderPublishConfirmDialog()}
      <AppBar className={classes.appBar}>
        <MuiToolbar className={classes.footerToolbar}>
          <div className={classes.actionsContainer}>
            <div className={classes.actions}>
              <Tooltip
                title={saveMailTooltipTitle()}
                placement="top"
                arrow
              >
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                    disabled={isLoading || !saveEmailIsValid()}
                  >
                    {i18n.get('save')}
                  </Button>
                </Typography>
              </Tooltip>
              <div className={classes.button}>
                <ContentApprovalStatusSelect
                  content={contentFormData}
                  mode="button"
                  buttonClass={classes.statusButton}
                />
              </div>
            </div>
            <div className={classes.actions}>
              {renderTestMailButton()}
              <Button
                variant="outlined"
                color="primary"
                onClick={() => openScheduleDialog()}
                className={classes.button}
                disabled={isLoading || title === ''}
              >
                <AccessTimeIcon className={classes.scheduleIcon} />
                {(!plannedFor && !scheduledFor) && i18n.get('plan')}
                {plannedFor && `${i18n.get('planned')}: ${moment(plannedFor).format('lll')}`}
                {scheduledFor && `${i18n.get('scheduled')}: ${moment(scheduledFor).format('lll')}`}
              </Button>
              {renderPublishNowButton()}
            </div>
          </div>
        </MuiToolbar>
      </AppBar>
    </>
  )
}

Toolbar.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPublishable: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTestMail: PropTypes.func.isRequired,
  openScheduleDialog: PropTypes.func.isRequired,
  setContentIsPublishable: PropTypes.func.isRequired
}
