import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Tooltip
} from '@mui/material'
import { green, purple, grey, orange } from '@mui/material/colors'
import MailIcon from '@mui/icons-material/MailOutline'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'

import { shorten } from 'utils/string'

const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: 20,
    margin: '-2px'
  },
  draft: {
    borderLeft: '5px solid',
    borderColor: grey[500]
  },
  reviewPending: {
    borderLeft: '5px solid',
    borderColor: purple[500]
  },
  revisionsNeeded: {
    borderLeft: '5px solid',
    borderColor: orange[500]
  },
  approved: {
    borderLeft: '5px solid',
    borderColor: green[500]
  }
}))

const ContentTitle = ({
  content,
  maxLength
}) => {
  const { classes } = useStyles()
  const type = content.get('type')

  return (
    <div>
      <div>
        {type === 'email' && (
          <MailIcon
            className={classes.icon}
            sx={{ color: green[500] }}
          />
        )}
        {type === 'linkedin' && (
          <LinkedInIcon
            className={classes.icon}
            sx={{ color: '#0077b5' }}
          />
        )}
        {type === 'facebook' && (
          <FacebookIcon
            className={classes.icon}
            sx={{ color: 'rgb(24, 119, 242)' }}
          />
        )}
      </div>
      <Tooltip
        title={(content.get('title') || '').length < maxLength ? '' : content.get('title')}
        placement="top"
        arrow
      >
        <span>
          {shorten(content.get('title'), maxLength)}
        </span>
      </Tooltip>
    </div>
  )
}

ContentTitle.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  maxLength: PropTypes.number.isRequired
}

export default ContentTitle
