/* global Image */
import React, { useState, useEffect, useRef } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'
import { useSnackbar } from 'notistack'
import { Map, List, fromJS } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { lighten, alpha, useTheme } from '@mui/material/styles'

import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Typography,
  LinearProgress
} from '@mui/material'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import UploadMedia from 'containers/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/UploadMedia'
import PdfMedia from 'containers/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/PdfMedia'
import MediaActions from 'containers/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/MediaActions'

import { formatBytes } from 'utils/number'
import { getMediaFileSrcAndFit } from 'utils/content_desk'

const allowedTotalAttachmentsSize = 12582912 // 12MB
const allowedTotalAttachments = 3

const allowedDocumentMimeTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv'
]

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  innerContainer: {
    overflow: 'hidden'
  },
  pdfContainer: {
    position: 'relative',
    aspectRatio: '1 / 1',
    width: '100%',
    overflow: 'hidden'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  imageListItem: {
    overflow: 'inherit',
    cursor: 'pointer'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: '2px solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tooltip: {
    backgroundColor: theme.palette.grey[700],
    padding: '10px',
    borderRadius: '5px'
  },
  tooltipInfo: {
    wordBreak: 'break-all',
    fontSize: '14px'
  },
  fileNameLabel: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  uploadMediaLabel: {
    fontWeight: '500'
  },
  statusBar: {
    backgroundColor: 'transparent',
    zIndex: '1',
    padding: '10px'
  },
  checkIcon: {
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  spacerListItem: {
    overflow: 'inherit'
  },
  spacerItem: {
    minHeight: '215px',
    aspectRatio: '1 / 1'
  },
  uploadListItem: {
    overflow: 'inherit'
  },
  uploadListItemBar: {
    textAlign: 'center'
  }
}))

const FilesGallery = ({
  contentFormData,
  uploadedMediaFiles,
  attachmentsMediaType,
  loadMoreDisabled,
  requestRunning,
  fetchMore,
  onChange
}) => {
  const i18n = useI18n()
  const theme = useTheme()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const device = useDevice()

  const parentDivRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [hoveredMediaId, setHoveredMediaId] = useState(null)
  const [imageDimensions, setImageDimensions] = useState(Map())

  const currentSelectedMediaFiles = contentFormData.get('ccdMediaFiles')
  const currentSelectedMediaFileIds = currentSelectedMediaFiles.map(f => f.get('id'))
  const currentAttachmentsSize = currentSelectedMediaFiles.reduce((acc, selectedMediaFile) => acc + selectedMediaFile.get('size'), 0)

  const files = uploadedMediaFiles.filter(f => f.get('mimeType') !== null)
  let mediaFiles

  if (attachmentsMediaType === 'image') {
    mediaFiles = files.filter(f => f.get('mimeType').startsWith('image/'))
  } else if (attachmentsMediaType === 'document') {
    mediaFiles = files.filter(f => allowedDocumentMimeTypes.includes(f.get('mimeType')))
  } else {
    mediaFiles = files.filter(f => allowedDocumentMimeTypes.includes(f.get('mimeType')) || f.get('mimeType').startsWith('image/'))
  }

  let galleryColumns

  if (device.get('md')) {
    galleryColumns = 4
  } else if (device.get('sm')) {
    galleryColumns = 3
  } else if (device.get('xs')) {
    galleryColumns = 2
  } else {
    galleryColumns = 5
  }

  useEffect(() => {
    if (parentDivRef.current) {
      const { offsetWidth, offsetHeight } = parentDivRef.current
      setDimensions({ width: offsetWidth, height: offsetHeight })
    }
  }, [uploadedMediaFiles])

  useEffect(() => {
    const allDimensionsFetched = mediaFiles.every(mediaFile => imageDimensions.has(mediaFile.get('id')))

    if (!allDimensionsFetched) {
      mediaFiles.forEach(mediaFile => {
        const { src } = getMediaFileSrcAndFit(mediaFile)
        const img = new Image()
        img.onload = () => {
          setImageDimensions(prevState => prevState.set(mediaFile.get('id'), { width: img.width, height: img.height }))
        }
        img.src = src
      })
    }
  }, [mediaFiles])

  const isHovered = mediaFile => hoveredMediaId === mediaFile.get('id')
  const isSelected = mediaFile => currentSelectedMediaFileIds.includes(mediaFile.get('id'))
  const isPdf = mediaFile => mediaFile.get('mimeType') === 'application/pdf'
  const isImage = mediaFile => mediaFile.get('mimeType').startsWith('image/')

  const onEnterWaypoint = () => {
    if (!loadMoreDisabled && !requestRunning) {
      fetchMore({})
    }
  }

  const exceedsSizeLimit = (deselecting, selectedMediaFile) => {
    if (deselecting) {
      return false
    }

    return allowedTotalAttachmentsSize < (currentAttachmentsSize + selectedMediaFile.get('size'))
  }

  const exceedsFileLimit = deselecting => {
    if (!deselecting && currentSelectedMediaFileIds.size + 1 > allowedTotalAttachments) {
      return true
    }

    return false
  }

  const toggleMediaSelect = selectedMediaFile => {
    const deselecting = currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))

    if (exceedsFileLimit(deselecting) || exceedsSizeLimit(deselecting, selectedMediaFile)) {
      return enqueueSnackbar(
        `${i18n.get('attachments_limit_warning', { number: allowedTotalAttachments, size: formatBytes(allowedTotalAttachmentsSize) })}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    let newSelected = List()

    if (deselecting) {
      newSelected = currentSelectedMediaFiles.filter(selected => selected.get('id') !== selectedMediaFile.get('id'))
    } else {
      newSelected = currentSelectedMediaFiles.unshift(fromJS(selectedMediaFile))
    }

    return onChange({ key: 'ccdMediaFiles', value: newSelected })
  }

  const renderOverlay = mediaFile => (
    <Box
      className={classes.overlay}
      style={{
        backgroundColor: isHovered(mediaFile) ? alpha(lighten(theme.palette.primary.main, 0.95), 0.9) : 'transparent',
        borderColor: (isHovered(mediaFile) || isSelected(mediaFile)) ? theme.palette.primary.main : theme.palette.grey[200]
      }}
    >
      {isHovered(mediaFile) && (
        <MediaActions
          mediaFile={mediaFile}
          isPdf={isPdf(mediaFile)}
        />
      )}
    </Box>
  )

  const renderFileImage = mediaFile => {
    const { src } = getMediaFileSrcAndFit(mediaFile)

    if (isPdf(mediaFile)) {
      return (
        <Box
          ref={parentDivRef}
          className={classes.pdfContainer}
        >
          <PdfMedia
            mediaFile={mediaFile}
            width={dimensions.width}
            height={dimensions.height}
          />
          {renderOverlay(mediaFile)}
        </Box>
      )
    }

    return (
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          src={src}
          srcSet={src}
          alt={mediaFile.get('id')}
          loading="lazy"
          style={{ padding: isImage(mediaFile) ? '0' : '30px' }}
        />
        {renderOverlay(mediaFile)}
      </Box>
    )
  }

  const renderSpacer = size => {
    if (size > 0) {
      return null
    }

    return (
      <ImageListItem className={classes.spacerListItem}>
        <Box className={classes.spacerItem} />
      </ImageListItem>
    )
  }

  const renderStatusIcon = mediaFile => {
    if (isHovered(mediaFile) || isSelected(mediaFile)) {
      return (
        <ImageListItemBar
          className={classes.statusBar}
          position="top"
          actionPosition="left"
          actionIcon={(
            <CheckCircleRoundedIcon
              className={classes.checkIcon}
              sx={{
                color: () => {
                  if (isSelected(mediaFile)) {
                    return theme.palette.primary.main
                  }

                  if (isHovered(mediaFile)) {
                    return theme.palette.grey[500]
                  }

                  return 'transparent'
                }
              }}
            />
          )}
        />
      )
    }

    return null
  }

  const renderTooltip = mediaFile => (
    <Box>
      <Typography className={classes.tooltipInfo}>
        <b>{i18n.get('file_name')}: </b>{mediaFile.get('filename')}
      </Typography>
      <Typography className={classes.tooltipInfo}>
        <b>{i18n.get('file_size')}: </b>{formatBytes(mediaFile.get('size'))}
      </Typography>
      {isImage(mediaFile) && imageDimensions.get(mediaFile.get('id')) && (
        <Typography className={classes.tooltipInfo}>
          <b>{i18n.get('dimensions')}: </b>
          {imageDimensions.get(mediaFile.get('id')).width} x {imageDimensions.get(mediaFile.get('id')).height}
        </Typography>
      )}
    </Box>
  )

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <ImageList
          cols={galleryColumns}
          gap={15}
        >
          <ImageListItem className={classes.uploadListItem}>
            <UploadMedia />
            <ImageListItemBar
              className={classes.uploadListItemBar}
              position="below"
              title={<Typography className={classes.uploadMediaLabel}>{i18n.get('upload_file')}</Typography>}
            />
          </ImageListItem>
          {renderSpacer(mediaFiles.size)}
          {mediaFiles.map(mediaFile => (
            <ImageListItem
              className={classes.imageListItem}
              key={mediaFile.get('id')}
              onClick={_e => toggleMediaSelect(mediaFile)}
              onMouseEnter={() => setHoveredMediaId(mediaFile.get('id'))}
              onMouseLeave={() => setHoveredMediaId(null)}
            >
              {renderStatusIcon(mediaFile)}
              {renderFileImage(mediaFile)}
              <ImageListItemBar
                position="below"
                title={(
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={renderTooltip(mediaFile)}
                    placement="bottom"
                  >
                    <Typography className={classes.fileNameLabel}>
                      {mediaFile.get('filename')}
                    </Typography>
                  </Tooltip>
                )}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Waypoint onEnter={onEnterWaypoint} />
        {requestRunning && <LinearProgress variant="indeterminate" />}
      </Box>
    </Box>
  )
}

FilesGallery.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  uploadedMediaFiles: PropTypes.instanceOf(List).isRequired,
  attachmentsMediaType: PropTypes.string.isRequired,
  loadMoreDisabled: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,

  fetchMore: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default FilesGallery
