import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'
import moment from 'moment-timezone'

import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  date: moment().startOf('month').format('YYYY-MM-DD'),
  contents: {
    total: null,
    items: []
  },
  campaigns: {
    total: null,
    items: []
  },
  selectedCampaignId: null,
  selectedDay: moment().format('YYYY-MM-DD'),
  contentsSorting: {
    field: 'updatedAt',
    order: 'desc'
  },
  campaignsSorting: {
    field: 'startDate',
    order: 'desc'
  }
})

const sortItems = (items, field, order) => (
  items.sortBy(item => item.get(field)).update(thingies => (
    order === 'desc' ? thingies.reverse() : thingies
  ))
)

const updateEntitiy = (state, entity, payload) => (
  state.updateIn([entity, 'items'], items => (
    items.map(item => (
      (item.get('id') === payload.id) ? fromJS(payload) : item
    ))
  ))
)

export default handleActions({
  [Actions.setCalendarDate]: (state, { payload }) => state.set('date', payload),
  [Actions.fetchCalendarEventsStart]: state => (
    state.set('contents', initialState.get('contents'))
      .set('campaigns', initialState.get('campaigns'))
  ),
  [Actions.fetchCalendarEventsSuccess]: (state, { payload: { campaigns, contents } }) => (
    state.set('contents', fromJS({
      total: contents.total,
      items: sortItems(
        fromJS(contents.items).map(c => c.set('eventType', 'content')),
        state.getIn(['contentsSorting', 'field']),
        state.getIn(['contentsSorting', 'order'])
      )
    }))
      .set('campaigns', fromJS({
        total: campaigns.total,
        items: sortItems(
          fromJS(campaigns.items).map(c => c.set('eventType', 'campaign')),
          state.getIn(['campaignsSorting', 'field']),
          state.getIn(['campaignsSorting', 'order'])
        )
      }))
  ),
  [Actions.setCalendarSelectedCampaignId]: (state, { payload }) => state.set('selectedCampaignId', payload),
  [Actions.setCalendarSelectedDay]: (state, { payload }) => state
    .set('selectedDay', payload)
    .set('selectedCampaignId', null),
  [combineActions(
    Actions.saveCampaignSuccess,
    Actions.saveCampaignFormlessSuccess
  )]: (state, { payload }) => (
    updateEntitiy(state, 'campaigns', payload)
  ),
  [combineActions(
    Actions.saveContentSuccess,
    Actions.saveContentFormlessSuccess
  )]: (state, { payload }) => (
    updateEntitiy(state, 'contents', payload)
  ),
  [Actions.sortContents]: (state, { payload: { field, order } }) => (
    state
      .set('contentsSorting', fromJS({ field, order }))
      .updateIn(['contents', 'items'], items => (
        sortItems(items, field, order)
      ))
  ),
  [Actions.sortCampaigns]: (state, { payload: { field, order } }) => (
    state
      .set('campaignsSorting', fromJS({ field, order }))
      .updateIn(['campaigns', 'items'], items => (
        sortItems(items, field, order)
      ))
  ),
  [Actions.setUpdatedContents]: (state, { payload }) => {
    let newState = state

    payload.forEach(p => {
      const contentId = p.id
      const updated = fromJS(p)

      if (newState.getIn(['contents', 'items']).filter(c => c.get('id') === contentId).size) {
        newState = newState
          .updateIn(['contents', 'items'], contents => (
            contents.map(content => {
              if (content.get('id') === contentId) {
                return updated
              }

              return content
            })
          ))
      }
    })

    return newState
  },
  [AppActions.resetState]: () => initialState
}, initialState)
