import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Tooltip,
  Typography
} from '@mui/material'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'

import { red, green, blue, pink } from '@mui/material/colors'

const useStyles = makeStyles()(theme => ({
  contentActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  moreActionsButton: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  select: {
    borderRadius: 25,
    fontSize: '0.70rem',
    color: 'white',
    '& .MuiSelect-icon': {
      color: 'white'
    }
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: !theme.isDark && '#404040'
  },
  status: {
    marginLeft: '0.2em'
  },
  deleted: {
    color: red[500]
  }
}))

const renderStatusIcon = status => {
  switch (status) {
    case 'failed':
      return <CancelIcon style={{ color: red[500] }} />
    case 'released':
      return <CheckCircleIcon style={{ color: green[500] }} />
    case 'scheduled':
      return <AccessTimeIcon style={{ color: blue[500] }} />
    case 'planned':
      return <TimerOutlinedIcon style={{ color: pink[900] }} />
    default:
      return <RemoveOutlinedIcon />
  }
}

function ColumnFooter({ text, fontColor }) {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ color: fontColor || (theme => !theme.isDark && '#757575') }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default function ContentStatus({
  content,
  mode
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const status = content.get('status')
  const plannedForDate = content.get('plannedFor')
  const isPlannedForBeforeNow = moment(plannedForDate).isBefore(moment())

  const getStatusDate = () => {
    if (status === 'released') {
      return moment(content.get('scheduledFor')).format('lll')
    }

    if (status === 'scheduled') {
      return moment(content.get('scheduledFor')).format('lll')
    }

    if (status === 'planned') {
      return moment(content.get('plannedFor')).format('lll')
    }

    return null
  }

  const renderStatus = () => {
    if (mode === 'short') {
      return (
        <Tooltip
          title={getStatusDate()}
          placement="top"
          arrow
        >
          <Box className={classes.statusWrapper}>
            {renderStatusIcon(status)}
            <div className={classes.status}>{i18n.get(status)}</div>
            {content.get('deletedOnSocial') && (
              <div>&nbsp;|&nbsp;<span className={classes.deleted}>{i18n.get('deleted')}</span></div>
            )}
          </Box>
        </Tooltip>
      )
    }

    return (
      <Box>
        <div className={classes.statusWrapper}>
          <div className={classes.status}>{i18n.get(status)}</div>
          {content.get('deletedOnSocial') && (
            <div>&nbsp;|&nbsp;<span className={classes.deleted}>{i18n.get('deleted')}</span></div>
          )}
        </div>

        <div className={classes.statusWrapper}>
          {renderStatusIcon(status)}
          {status === 'scheduled' && <ColumnFooter text={getStatusDate()} />}
          {status === 'released' && <ColumnFooter text={getStatusDate()} />}
          {status === 'planned' && (
          <ColumnFooter
            text={getStatusDate()}
            fontColor={isPlannedForBeforeNow ? red[400] : null}
          />
          )}
        </div>
      </Box>
    )
  }

  return (
    <Box>
      {renderStatus()}
    </Box>
  )
}

ContentStatus.defaultProps = {
  mode: 'full'
}

ContentStatus.propTypes = {
  content: PropTypes.instanceOf(ImmutableMap).isRequired,
  mode: PropTypes.string
}

ColumnFooter.propTypes = {
  text: PropTypes.string,
  fontColor: PropTypes.string
}

