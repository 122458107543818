import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  CircularProgress,
  Paper,
  Grid,
  Typography,
  Card,
  CardContent
} from '@mui/material'
import { lighten } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add'
import MailIcon from '@mui/icons-material/MailOutline'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'

const useStyles = makeStyles()(theme => ({
  container: {
    borderRadius: 20,
    minHeight: 200,
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  loadingContainer: {
    textAlign: 'center'
  },
  col: {
    maxWidth: 300
  },
  card: {
    borderRadius: 20,
    width: 280,
    cursor: 'pointer',
    boxShadow: 'none',
    height: 130,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    }
  },
  title: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    borderRadius: 8,
    height: '1.6em',
    width: '1.6em',
    display: 'block',
    textAlign: 'center',
    marginBottom: 10
  },
  mailIcon: {
    fontSize: 16,
    verticalAlign: 'middle'
  },
  socialIcon: {
    fontSize: 12,
    margin: -1
  }
}))

export default function Calendar({
  hasLinkedIn,
  hasFacebook,
  isContentLoading,
  openContent,
  openCampaignForm,
  openContentDialog
}) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const renderSocialMediaCreateCard = () => {
    if (!hasLinkedIn && !hasFacebook) {
      return null
    }

    return (
      <Grid
        xs={12}
        md={4}
        className={classes.col}
        item
      >
        <Card
          onClick={() => openContentDialog({ socialOnly: true })}
          className={classes.card}
        >
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              className={classes.title}
            >
              {hasLinkedIn && (<LinkedInIcon className={classes.socialIcon} />)}
              {hasFacebook && (<FacebookIcon className={classes.socialIcon} />)}
            </Box>
            <Typography
              variant="subtitle2"
            >
              {i18n.get('create_social_content')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 12, mt: 1 }}
            >
              {i18n.get('create_social_content_description')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  return (
    <Paper
      sx={{ p: 2 }}
      elevation={0}
      className={classes.container}
    >
      <Typography
        variant="h5"
        sx={{ m: 1 }}
      >
        {i18n.get('what_to_do')}
      </Typography>
      <Box>
        <Grid
          spacing={1}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid
            xs={12}
            md={6}
            lg={4}
            className={classes.col}
            item
          >
            <Card
              onClick={() => openCampaignForm()}
              className={classes.card}
            >
              <CardContent>
                <Box className={classes.title}>
                  <AddIcon />
                </Box>
                <Typography
                  variant="subtitle2"
                >
                  {i18n.get('create_campaign')}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, mt: 1 }}
                >
                  {i18n.get('create_campaign_description')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            xs={12}
            md={4}
            className={classes.col}
            item
          >
            <Card
              onClick={() => openContent({ type: 'email' })}
              className={classes.card}
            >
              <CardContent>
                <Box className={classes.title}>
                  <MailIcon
                    className={classes.mailIcon}
                  />
                </Box>
                {isContentLoading ? (
                  <Box className={classes.loadingContainer}>
                    <CircularProgress
                      color="primary"
                      size={26}
                    />
                  </Box>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                    >
                      {i18n.get('send_mail_content')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 12, mt: 1 }}
                    >
                      {i18n.get('send_mail_content_description')}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          {renderSocialMediaCreateCard()}
        </Grid>
      </Box>
    </Paper>
  )
}

Calendar.propTypes = {
  hasLinkedIn: PropTypes.bool.isRequired,
  hasFacebook: PropTypes.bool.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  openContent: PropTypes.func.isRequired,
  openContentDialog: PropTypes.func.isRequired,
  openCampaignForm: PropTypes.func.isRequired
}
