import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { red } from '@mui/material/colors'

import {
  Box,
  LinearProgress,
  Typography
} from '@mui/material'

import { uploadedMediaFilesSizeLimit } from 'static/constants'
import { formatBytes } from 'utils/number'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  storageLabel: {
    fontSize: '16px'
  },
  progress: {
    alignItems: 'center',
    display: 'flex',
    minWidth: '200px'
  },
  progressBar: {
    width: '100%'
  },
  usageLimitLabel: {
    fontSize: '12px',
    opacity: '0.5'
  }
})

const StorageLimit = ({
  uploadedMediaFilesTotalSize
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const calcPercentage = () => {
    const percentage = Math.round((100 * uploadedMediaFilesTotalSize) / uploadedMediaFilesSizeLimit)

    return percentage > 100 ? 100 : percentage
  }

  const isMax = calcPercentage() >= 100

  return (
    <Box className={classes.container}>
      <Typography className={classes.storageLabel}>
        {i18n.get('storage')}
      </Typography>
      <Box className={classes.progress}>
        <Box className={classes.progressBar}>
          <LinearProgress
            variant="determinate"
            value={calcPercentage(uploadedMediaFilesTotalSize, uploadedMediaFilesSizeLimit)}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: isMax && red[500]
              }
            }}
          />
        </Box>
      </Box>
      <Typography className={classes.usageLimitLabel}>
        {formatBytes(uploadedMediaFilesTotalSize)} / {formatBytes(uploadedMediaFilesSizeLimit)}
      </Typography>
    </Box>
  )
}

StorageLimit.propTypes = {
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired
}

export default StorageLimit
