import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { makeStyles } from 'tss-react/mui'

import { orange } from '@mui/material/colors'
import { lighten, darken } from '@mui/material/styles'

import {
  Box,
  Typography
} from '@mui/material'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { uploadedMediaFilesSizeLimit } from 'static/constants'

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    gap: '15px',
    backgroundColor: lighten(orange[50], 0.5),
    border: '1px solid',
    borderRadius: '5px',
    borderColor: orange[800],
    padding: '20px'
  },
  titleLabel: {
    fontSize: '18px',
    fontWeight: '500',
    color: darken(orange[800], 0.5),
    marginBottom: '5px'
  },
  infoLabel: {
    fontSize: '14px',
    color: theme.palette.text.secondary
  },
  icon: {
    color: orange[800]
  }
}))

const StorageWarning = ({
  uploadedMediaFilesTotalSize
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const device = useDevice()

  const calcPercentage = () => {
    const percentage = Math.round((100 * uploadedMediaFilesTotalSize) / uploadedMediaFilesSizeLimit)

    return percentage > 100 ? 100 : percentage
  }

  const show = () => {
    if (device.get('mobile')) {
      return calcPercentage() >= 85
    }

    return calcPercentage() >= 90
  }

  if (!show()) {
    return null
  }

  return (
    <Box className={classes.container}>
      <WarningAmberIcon className={classes.icon} />
      <Box>
        <Typography className={classes.titleLabel}>
          {i18n.get('not_enough_storage')}?
        </Typography>
        <Typography
          className={classes.infoLabel}
          dangerouslySetInnerHTML={{
            __html: i18n.get('not_enough_storage_info', { email: 'sales@pressrelations.de' })
          }}
        />
      </Box>
    </Box>
  )
}

StorageWarning.propTypes = {
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired
}

export default StorageWarning
