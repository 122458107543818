import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useColors from 'hooks/useColors'
import Avatar from '@mui/material/Avatar'
import { Map } from 'immutable'

import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'containers/themed'

import { isEditorDesignBlank } from 'utils/content_desk'

const ButtonContainer = props => <div {...props} />

const useStyles = makeStyles()(theme => ({
  switchActions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  switchAction: {
    position: 'relative',
    flexBasis: 0,
    '& > button': {
      minHeight: '100%',
      [theme.breakpoints.only('xs')]: {
        fontSize: '7pt'
      }
    }
  },
  switchActionContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    whiteSpace: 'nowrap',
    opacity: 0.7
  },
  avatarStep: {
    marginRight: 5,
    width: 24,
    height: 24,
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 8
    }
  },
  warnClass: {
    color: theme.palette.warning.main
  },
  avatarWarnClass: {
    backgroundColor: theme.palette.warning.main
  }
}))

export default function Navigation({
  contentFormData,
  contentsLoading,
  view,
  onNavigationChange
}) {
  const i18n = useI18n()
  const colors = useColors()
  const { classes } = useStyles()

  const handleTabChange = buttonView => {
    if (view === buttonView) {
      return
    }

    onNavigationChange(buttonView)
  }

  const renderNavigationAction = (step, buttonView, label, loading, isDisabled = false, Comp = ButtonContainer) => {
    const warnIcon = (
      <PriorityHighIcon
        size="small"
        color="warning"
      />
    )

    let showWarnIcon = false
    let warnClass
    let avatarWarnClass
    let active

    if (view === buttonView) {
      active = (
        <div
          style={{ backgroundColor: colors.get('primary') }}
        />
      )
    }

    if (buttonView === 'settings') {
      const title = contentFormData.get('title')
      const subject = contentFormData.get('subject') || ''
      const senderMail = contentFormData.get('senderMail') || ''

      if (!subject || !title || !senderMail) {
        showWarnIcon = true
        warnClass = classes.warnClass
        avatarWarnClass = classes.avatarWarnClass
      }
    }

    if (buttonView === 'recipients') {
      if (contentFormData.get('recipients').size === 0) {
        showWarnIcon = true
        warnClass = classes.warnClass
        avatarWarnClass = classes.avatarWarnClass
      }
    }

    if (buttonView === 'editor') {
      if (isEditorDesignBlank(contentFormData.get('editorDesign'))) {
        showWarnIcon = true
        warnClass = classes.warnClass
        avatarWarnClass = classes.avatarWarnClass
      }
    }

    const tabButton = () => (
      <Button
        disableRipple
        onClick={() => handleTabChange(buttonView)}
        disabled={isDisabled}
        loading={loading}
      >
        <div
          className={`${classes.switchActionContent} ${warnClass}`}
          style={active && { color: colors.get('primary'), opacity: 1 }}
        >
          <Avatar
            className={`${classes.avatarStep} ${avatarWarnClass}`}
            sx={active && { bgcolor: colors.get('primary') }}
          >
            {step}
          </Avatar>
          {label}
          {showWarnIcon && warnIcon}
        </div>
      </Button>
    )

    return (
      <Comp
        key={buttonView}
        className={classes.switchAction}
      >
        {tabButton()}
        {active}
      </Comp>
    )
  }

  const buttons = []

  buttons.push(
    renderNavigationAction(
      1,
      'settings',
      `${i18n.get('setting')}`,
      contentsLoading
    )
  )

  buttons.push(
    renderNavigationAction(
      2,
      'recipients',
      `${i18n.get('recipients')}`,
      contentsLoading
    )
  )

  buttons.push(
    renderNavigationAction(
      3,
      'templates',
      `${i18n.get('templates')}`,
      contentsLoading
    )
  )

  buttons.push(
    renderNavigationAction(
      4,
      'editor',
      `${i18n.get('editor')}`,
      contentsLoading
    )
  )

  buttons.push(
    renderNavigationAction(
      5,
      'attachments',
      `${i18n.get('attachments')}`,
      contentsLoading
    )
  )

  buttons.push(
    renderNavigationAction(
      6,
      'overview',
      `${i18n.get('overview')}`,
      contentsLoading
    )
  )

  return (
    <div className={classes.switchActions}>
      {buttons}
    </div>
  )
}

Navigation.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  contentsLoading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,

  onNavigationChange: PropTypes.func.isRequired
}
