import { connect } from 'react-redux'

import ContentNewDialog from 'components/content_desk/content_new_dialog'

import { closeNewContentDialog, setContentFormData, saveContentStart } from 'actions/content_desk'
import {
  getCDeskShowNewContentDialog,
  getStaticCdContentTypes,
  getStaticFacebookAnalyticsPages,
  getLinkedInProfile,
  getCDeskNewContentDialogSocialOnly
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowNewContentDialog(state),
  pages: getStaticFacebookAnalyticsPages(state),
  profile: getLinkedInProfile(state),
  contentDeskContentTypes: getStaticCdContentTypes(state),
  socialOnly: getCDeskNewContentDialogSocialOnly(state)
})

export default connect(
  mapStateToProps,
  {
    closeNewContentDialog,
    setContentFormData,
    saveContentStart
  }
)(ContentNewDialog)
