import moment from 'moment-timezone'
// import { List } from 'immutable'
import { hexColorFromString } from 'utils/color'

// const enumerateDaysBetweenDates = (startDate, endDate) => {
//   const now = moment(startDate).startOf('day')
//   const endD = moment(endDate).endOf('day')

//   const dates = []

//   while (now.isBefore(endD) || now.isSame(endD)) {
//     dates.push(now.startOf('day').toDate())
//     now.add(1, 'days')
//   }

//   return List(dates)
// }

export const calendarEvents = events => events
  .filter(c => (c.get('scheduledFor') || c.get('plannedFor')) || (c.get('startDate') && c.get('endDate')))
  .map(event => {
    const title = event.get('name')
    const campaignName = event.getIn(['ccdCampaign', 'name']) || event.get('name')
    const color = hexColorFromString(campaignName)
    const eventType = event.get('eventType')

    if (eventType === 'content') {
      const type = event.get('type')
      const scheduledForDate = event.get('scheduledFor')
      const plannedForDate = event.get('plannedFor')

      let time
      let status
      let date

      if (!scheduledForDate && plannedForDate) {
        status = 'planned'
        date = moment(plannedForDate).toDate()
        time = moment(plannedForDate).format('LT')
      } else {
        status = event.get('status') || event.get('approvalStatus')
        date = moment(scheduledForDate).toDate()
        time = moment(scheduledForDate).format('LT')
      }

      return {
        title,
        display: 'block',
        extendedProps: {
          type,
          eventType,
          campaignName,
          time,
          status,
          content: event
        },
        date,
        start: date
      }
    }

    const start = moment(event.get('startDate')).toDate()
    const end = moment(event.get('endDate')).toDate()

    return {
      title,
      display: 'block',
      extendedProps: {
        eventType,
        campaignName,
        campaign: event,
        color
      },
      date: start,
      start,
      end
    }
  })

export const calendarWidgetEvents = (contents, selectedCampaignId, theme) => contents
  .filter(c => (c.get('scheduledFor') || c.get('plannedFor')) || (c.get('startDate') && c.get('endDate')))
  .filter(event => !selectedCampaignId || (
    event.getIn(['ccdCampaign', 'id']) === selectedCampaignId
  ))
  .map(event => {
    // if (event.get('eventType') === 'content') {
    const scheduledForDate = event.get('scheduledFor')
    const plannedForDate = event.get('plannedFor')

    let date

    if (!scheduledForDate && plannedForDate) {
      date = moment(plannedForDate).startOf('day').format('YYYY-MM-DD')
    } else {
      date = moment(scheduledForDate).format('YYYY-MM-DD')
    }

    return {
      start: date,
      end: moment(date).add(1, 'day').format('YYYY-MM-DD'),
      display: 'background',
      overlap: true,
      color: theme.palette.primary.main
    }
  }).filter(e => e)
