import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Map } from 'immutable'
import camelcase from 'camelcase'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  CircularProgress,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { green, purple, grey, orange } from '@mui/material/colors'

import useDevice from 'hooks/useDevice'

import ContentTitle from 'containers/content_desk_new/contents/content/ContentTitle'
import ContentStatus from 'containers/content_desk_new/contents/content/ContentStatus'
import ContentActions from 'containers/content_desk_new/contents/content/ContentActions'

const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: 20,
    margin: '-2px'
  },
  draft: {
    borderLeft: '5px solid',
    borderColor: grey[500]
  },
  reviewPending: {
    borderLeft: '5px solid',
    borderColor: purple[500]
  },
  revisionsNeeded: {
    borderLeft: '5px solid',
    borderColor: orange[500]
  },
  approved: {
    borderLeft: '5px solid',
    borderColor: green[500]
  }
}))

function ColumnFooter({ text }) {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ color: theme => !theme.isDark && '#757575' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const ShortContent = ({
  content,
  requestRunning
}) => {
  const device = useDevice()
  const { classes } = useStyles()
  const editor = `${content.getIn(['lastEditor', 'firstName'])} ${content.getIn(['lastEditor', 'lastName'])}`
  const lastEditedDate = `${moment(content.get('updatedAt')).format('L')}, ${moment(content.get('updatedAt')).format('LT')}`
  const status = camelcase(content.get('approvalStatus'))
  const statusClass = classes[status]
  let maxLength = 16

  if (device.get('xl')) {
    maxLength = 24
  } else if (device.get('lg')) {
    maxLength = 20
  }

  return (
    <TableRow className={statusClass}>
      <TableCell>
        <ContentTitle
          content={content}
          maxLength={maxLength}
        />
      </TableCell>
      <TableCell>
        {editor}
        <ColumnFooter text={lastEditedDate} />
      </TableCell>
      <TableCell>
        {requestRunning ? (
          <CircularProgress size={40} />
        ) : (
          <ContentStatus
            content={content}
            mode="short"
          />
        )}
      </TableCell>
      <TableCell>
        <ContentActions
          content={content}
        />
      </TableCell>
    </TableRow>
  )
}

ShortContent.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired
}

ColumnFooter.propTypes = {
  text: PropTypes.string
}

export default ShortContent
