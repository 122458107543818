import { connect } from 'react-redux'

import RecipientsBrowser from 'components/content_desk_new/contents/content_edit_dialog/recipients_browser'

import {
  getCDeskContentFormDataRecipients,
  getCDeskRecipientBrowserOpen,
  getCDeskRecipientBrowserQuery
} from 'selectors'

import {
  setRecipientBrowserOpen,
  setRecipientBrowserQuery as onQueryChange,
  setContentFormData
} from 'actions/content_desk'

const mapStateToProps = state => ({
  recipients: getCDeskContentFormDataRecipients(state),
  open: getCDeskRecipientBrowserOpen(state),
  query: getCDeskRecipientBrowserQuery(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: () => setRecipientBrowserOpen(false),
    onQueryChange,
    setContentFormData
  }
)(RecipientsBrowser)
