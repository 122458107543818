import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { alpha } from '@mui/material/styles'
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  Box,
  Chip,
  CircularProgress
} from '@mui/material'
import { grey, red, green } from '@mui/material/colors'
import InfoIcon from '@mui/icons-material/Info'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'

const useStyles = makeStyles()({
  card: {
    borderRadius: 20,
    minHeight: 147,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200]
  },
  cardContent: {
    padding: '18px 18px 18px 18px'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '28px',
    gap: '10px'
  },
  title: {
    fontSize: '13px',
    fontWeight: '500',
    marginBottom: 0,
    opacity: 0.8,
    lineHeight: '1.1'
  },
  infoIcon: {
    color: grey[600]
  },
  diffWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  value: {
    fontSize: '28px',
    fontWeight: 500,
    marginBottom: 10
  }
})

const StatisticsCard = ({
  title,
  info,
  value,
  difference,
  trend,
  type,
  timePeriod,
  loadingKpiStatistics
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  let comparedToLabel = ''
  switch (timePeriod) {
    case 'last_7_days':
      comparedToLabel = i18n.get('compared_to_previous_7_days')
      break
    case 'last_30_days':
      comparedToLabel = i18n.get('compared_to_previous_30_days')
      break
    case 'last_3_months':
      comparedToLabel = i18n.get('compared_to_previous_3_months')
      break
    default:
      break
  }

  const renderValue = () => ((value !== null && value !== undefined)
    ? `${value}${type === 'percentage' ? '%' : ''}`
    : '-')

  const renderChip = () => {
    let chipBackgroundColor
    let color
    let icon

    /* eslint-disable prefer-destructuring */
    if (trend === 'up') {
      chipBackgroundColor = alpha(green[100], 0.3)
      color = green[800]
      icon = <TrendingUpIcon sx={{ fill: color }} />
    }

    if (trend === 'down') {
      chipBackgroundColor = alpha(red[100], 0.3)
      color = red[800]
      icon = <TrendingDownIcon sx={{ fill: color }} />
    }

    if (trend === 'neutral') {
      chipBackgroundColor = alpha(grey[300], 0.3)
      color = grey[800]
      icon = <TrendingFlatIcon sx={{ fill: color }} />
    }
    /* eslint-enable prefer-destructuring */

    return (
      <Tooltip
        title={`${trend === 'up' ? '+' : ''}${Math.round(difference)}%`}
        arrow
        placement="bottom"
      >
        <Chip
          sx={{ backgroundColor: chipBackgroundColor, color, minWidth: '75px', maxWidth: '75px' }}
          icon={icon}
          label={`${trend === 'up' ? '+' : ''}${Math.round(difference)}%`}
          size="small"
        />
      </Tooltip>
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.titleWrapper}>
          <Typography
            className={classes.title}
            color="text.secondary"
            gutterBottom
          >
            {i18n.get(title)}
          </Typography>
          <Tooltip
            title={i18n.get(info)}
            arrow
            placement="top"
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </Box>
        <Typography className={classes.value}>
          {loadingKpiStatistics
            ? (
              <CircularProgress
                color="primary"
                size={26}
              />
            )
            : (
              renderValue()
            )}
        </Typography>
        <Box className={classes.diffWrapper}>
          {
            loadingKpiStatistics ? (
              <CircularProgress
                color="primary"
                size={16}
              />
            ) : (
              <>
                {(difference !== null && difference !== undefined) ? renderChip() : '-'}
                <Typography
                  fontSize={11}
                  color="text.secondary"
                  mr={0.5}
                >
                  {comparedToLabel}
                </Typography>
              </>
            )
          }
        </Box>
      </CardContent>
    </Card>
  )
}

StatisticsCard.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  value: PropTypes.number,
  difference: PropTypes.number,
  trend: PropTypes.string,
  type: PropTypes.string,
  timePeriod: PropTypes.string.isRequired,
  loadingKpiStatistics: PropTypes.bool.isRequired
}

export default StatisticsCard
