export const getMediaFileSrcAndFit = mf => {
  let src
  let style = { objectFit: 'fill' }

  switch (mf.get('mimeType')) {
    case 'application/msword':
      src = 'https://static.pressrelations.de/pics/groot/ccd/doc-icon.svg'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      src = 'https://static.pressrelations.de/pics/groot/ccd/docx-icon.svg'
      break
    case 'application/vnd.ms-excel':
      src = 'https://static.pressrelations.de/pics/groot/ccd/xls-icon.svg'
      break
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      src = 'https://static.pressrelations.de/pics/groot/ccd/xlsx-icon.svg'
      break
    case 'text/csv':
      src = 'https://static.pressrelations.de/pics/groot/ccd/csv-icon.svg'
      break
    case mf.get('mimeType').match(/^image/)?.input:
      src = mf.get('url')
      style = { objectFit: 'contain' }
      break
    default:
      src = 'https://static.pressrelations.de/pics/groot/ccd/unknown-format-icon.svg'
      break
  }

  return { src, style }
}

export const isEditorDesignBlank = editorDesign => {
  if (!editorDesign) {
    return true
  }

  const { body } = JSON.parse(editorDesign)
  const { rows } = body
  let isEmpty = true
  for (let i = 0; i < rows.length; i += 1) {
    const { columns } = rows[i]
    for (let j = 0; j < columns.length; j += 1) {
      const { contents } = columns[j]

      if (contents.length) {
        isEmpty = false
        break
      }
    }
  }

  return isEmpty
}
