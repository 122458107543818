import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List } from 'immutable'
import { DataGrid } from '@mui/x-data-grid'

import { makeStyles } from 'tss-react/mui'
import { IconButton } from 'containers/themed'
import ClearIcon from '@mui/icons-material/Clear'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

const useStyles = makeStyles()({
  tableContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '20px'
  },
  actions: {
    display: 'flex'
  }
})

const Recipients = ({
  recipients,
  removeRecipient,
  onViewClick
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handleRemove = row => {
    removeRecipient(row.id)
  }

  const renderRemove = params => (
    <IconButton
      className={classes.icon}
      size="small"
      onClick={() => handleRemove(params)}
      title={i18n.get('remove')}
    >
      <ClearIcon />
    </IconButton>
  )

  const renderView = params => (
    <IconButton
      size="small"
      onClick={() => onViewClick(params.row)}
      title={i18n.get('view')}
    >
      <RemoveRedEyeIcon />
    </IconButton>
  )

  const renderActions = params => (
    <div className={classes.actions}>
      {renderView(params)}
      {renderRemove(params)}
    </div>
  )

  const distributionName = row => {
    const dl = row.distributionLists || []

    if (dl.length < 1) {
      return ''
    }

    let { name } = dl[0]

    if (row.distributionListName) {
      name = row.distributionListName
    }

    if (dl.length > 1) {
      name += `, ${dl.length - 1}+`
    }

    return name
  }
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueGetter: (_value, row) => (
        `${row.firstName} ${row.lastName}`
      )
    },
    {
      field: 'mail',
      headerName: 'E-mail',
      width: 150
    },
    {
      field: 'distribution_list',
      headerName: 'Distribution List',
      width: 150,
      valueGetter: (_value, row) => distributionName(row)
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 80,
      renderCell: renderActions,
      sortable: false
    }
  ]

  return (
    <div className={classes.tableContainer}>
      <DataGrid
        columns={columns}
        rows={recipients.toJS()}
        hideFooter
        disableRowSelectionOnClick
      />
    </div>
  )
}

Recipients.propTypes = {
  recipients: PropTypes.instanceOf(List).isRequired,
  removeRecipient: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired
}

export default Recipients
