import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map, List, fromJS } from 'immutable'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'
import moment from 'moment'

import { makeStyles } from 'tss-react/mui'

import { Box, Autocomplete, TextField } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px'
  },
  autocompleteContainer: {
    width: '100%'
  },
  newCampaignButton: {
    gap: '5px',
    textWrap: 'nowrap'
  }
})

const CampaignSelection = ({
  campaigns,
  campaignOptionsLoading,
  content,
  updateContent,
  openCampaignForm,
  updateCampaignFormData,
  openClosedCampaignDialog
}) => {
  if (!content.get('ccdCampaign')) {
    return null
  }

  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const [selectedCampaign, setSelectedCampaign] = useState(content.get('ccdCampaign').toJS())

  const mapToOptions = options => options.map(campaign => ({
    label: campaign.get('name'),
    value: campaign.get('id'),
    category: (campaign.get('active') ? i18n.get('active') : i18n.get('closed')).toUpperCase()
  }))

  const handleCampaignChange = (_event, option) => {
    const campaign = campaigns.find(c => c.get('id') === option.value)
    const endDate = campaign.get('endDate')

    if (endDate && !campaign.get('active') && moment(endDate).isBefore(moment())) {
      updateCampaignFormData(campaign)
      openClosedCampaignDialog()
    }

    setSelectedCampaign(option)
    updateContent({ ccdCampaign: fromJS(option) })
  }

  const renderNewCampaignButton = () => {
    if (device.get('lt-sm')) {
      return (
        <IconButton
          color="primary"
          onClick={() => openCampaignForm()}
          title={i18n.get('create_campaign')}
        >
          <AddIcon />
        </IconButton>
      )
    }

    return (
      <Button
        className={classes.newCampaignButton}
        color="primary"
        onClick={() => openCampaignForm()}
      >
        <AddIcon color="primary" />
        {i18n.get('create_campaign')}
      </Button>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.autocompleteContainer}>
        <Autocomplete
          disableClearable
          options={mapToOptions(campaigns).sort((a, b) => -b.category.localeCompare(a.category)).toJS()}
          groupBy={option => option.category}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          loading={campaignOptionsLoading}
          getOptionLabel={option => option.label}
          value={selectedCampaign}
          onChange={handleCampaignChange}
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.get('campaign')}
              placeholder={i18n.get('select_ccd_campaign')}
              fullWidth
            />
          )}
        />
      </Box>
      <Box>
        {renderNewCampaignButton()}
      </Box>
    </Box>
  )
}

CampaignSelection.propTypes = {
  campaigns: PropTypes.instanceOf(List).isRequired,
  campaignOptionsLoading: PropTypes.bool.isRequired,
  content: PropTypes.instanceOf(Map).isRequired,

  updateContent: PropTypes.func.isRequired,
  openCampaignForm: PropTypes.func.isRequired,
  updateCampaignFormData: PropTypes.func.isRequired,
  openClosedCampaignDialog: PropTypes.func.isRequired
}

export default CampaignSelection
