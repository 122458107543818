import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Map } from 'immutable'
import moment from 'moment-timezone'

import { makeStyles } from 'tss-react/mui'
import { grey } from '@mui/material/colors'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { calendarWidgetEvents } from 'utils/calendar_events'
import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'
import CalendarEvents from 'containers/content_desk_new/CalendarEvents'

import useI18n from 'hooks/useI18n'
import { useTheme } from '@mui/material/styles'

export const getLocale = () => (moment.locale() || 'de')

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: '20px 20px 20px 20px',
    borderRadius: 20,
    position: 'relative',
    '& .fc-button': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      textTransform: 'uppercase',
      border: 'none',
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 0.8
      },
      '&:focus': {
        boxShadow: 'none !important'
      },
      '&:active': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 0.8
      }
    },
    '& .fc-day-other': {
      backgroundColor: grey[100]
    }
  },
  event: {
    display: 'flex',
    justifyContent: 'center'
  },
  overlay: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const CalendarWidget = ({
  contents,
  calendarDate,
  selectedDay,
  onNewContentClick,
  onNewCampaignClick,
  fetchContents,
  setCalendarDate,
  requestRunning,
  selectedCampaignId,
  setSelectedDay
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const theme = useTheme()

  const wrapperRef = useRef(null)
  const [newEventTop, setNewEventTop] = useState(200)
  const [newEventLeft, setNewEventLeft] = useState(200)
  const [newEventOpen, setNewEventOpen] = useState(false)
  const [newEventStartDate, setNewEventStartDate] = useState(new Date())
  const [newEventEndDate, setNewEventEndDate] = useState(new Date())
  const handleNewEventClose = () => {
    setNewEventOpen(false)
  }

  useEffect(() => {
    if (calendarDate) {
      fetchContents(calendarDate)
    }
  }, [calendarDate])

  const handleNewContentClick = () => {
    onNewContentClick({
      scheduledFor: moment(newEventEndDate).subtract(12, 'hours').toDate()
    })
    handleNewEventClose()
  }

  const handleNewCampaignClick = () => {
    onNewCampaignClick({
      startDate: moment(newEventStartDate).add(12, 'hours').toDate(),
      endDate: moment(newEventEndDate).subtract(12, 'hours').toDate()
    })
    handleNewEventClose()
  }

  const events = calendarWidgetEvents(contents.get('items'), selectedCampaignId, theme)

  const onCalendarNavClick = calendarApi => {
    setCalendarDate(moment(calendarApi.getDate()).format('YYYY-MM-DD'))
  }

  const onDaySelect = info => {
    setNewEventStartDate(info.start)
    setNewEventEndDate(info.end)
    const newSelectedDay = moment(info.start).format('YYYY-MM-DD')

    if (info.jsEvent) {
      setNewEventTop(info.jsEvent.clientY)
      setNewEventLeft(info.jsEvent.clientX)

      if (selectedDay === newSelectedDay) {
        setNewEventOpen(true)
      }
    }

    setSelectedDay(newSelectedDay)
  }

  const calendarConfig = {
    customButtons: {
      prevButton: {
        text: i18n.get('previous'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.prev()
          onCalendarNavClick(calendarApi)
        }
      },
      nextButton: {
        text: i18n.get('next'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.next()
          onCalendarNavClick(calendarApi)
        }
      },
      todayButton: {
        text: i18n.get('today'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.today()
          onCalendarNavClick(calendarApi)
        }
      }
    },
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'prevButton,nextButton todayButton'
    },
    buttonIcons: {
      prevButton: 'chevron-left',
      nextButton: 'chevron-right'
    },
    titleFormat: {
      month: 'long'
    },
    initialDate: (() => {
      if (events.isEmpty()) {
        return moment().format('YYYY-MM-DD')
      }

      return moment(events.last().start).format('YYYY-MM-DD')
    })(),
    plugins: [dayGridPlugin, interactionPlugin],
    firstDay: 1,
    // dayMaxEventRows: true,
    editable: false,
    selectable: true,
    select: onDaySelect,
    contentHeight: 500
  }

  return (
    <div className={classes.container}>
      {requestRunning && (
        <div className={classes.overlay}>
          <div className={classes.progressBar}>
            <NrxProgressBar />
          </div>
        </div>
      )}
      <FullCalendar
        ref={wrapperRef}
        locales={allLocales}
        locale={getLocale()}
        events={events.toJS()}
        {...calendarConfig}
      />
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={{ top: newEventTop, left: newEventLeft }}
        open={newEventOpen}
        onClose={handleNewEventClose}
      >
        <MenuItem onClick={handleNewContentClick}>{i18n.get('create_content')}</MenuItem>
        <MenuItem onClick={handleNewCampaignClick}>{i18n.get('create_campaign')}</MenuItem>
      </Menu>

      <CalendarEvents />
    </div>
  )
}

CalendarWidget.propTypes = {
  contents: PropTypes.instanceOf(Map).isRequired,
  calendarDate: PropTypes.string.isRequired,
  selectedDay: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  selectedCampaignId: PropTypes.number,
  onNewContentClick: PropTypes.func.isRequired,
  onNewCampaignClick: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  setCalendarDate: PropTypes.func.isRequired,
  setSelectedDay: PropTypes.func.isRequired
}

export default CalendarWidget
