import { connect } from 'react-redux'

import ContentActions from 'components/content_desk_new/contents/content/content_actions'

import { getStaticCdContentTypes } from 'selectors'

import {
  lockContentStart as onEditClick,
  cloneContentsFormData as onCloneClick,
  openViewContentDialog as onViewClick,
  switchToSearchPool as onSwitchToSearchPoolClick,
  saveContentStart
} from 'actions/content_desk'

const mapStateToProps = (state, { content }) => ({
  content,
  contentDeskContentTypes: getStaticCdContentTypes(state)
})

export default connect(
  mapStateToProps,
  {
    onEditClick,
    onCloneClick,
    onViewClick,
    onSwitchToSearchPoolClick,
    saveContentStart
  }
)(ContentActions)
