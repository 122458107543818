import { connect } from 'react-redux'

import { openCalendarDialog } from 'actions/content_desk'

import Actions from 'components/content_desk_new/actions/Actions'

const mapStateToProps = _state => ({

})

export default connect(
  mapStateToProps,
  {
    openCalendarDialog
  }
)(Actions)
