import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { fromJS, List, Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { Badge, MenuItem } from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PoolIcon from '@mui/icons-material/Pool'
import NotesIcon from '@mui/icons-material/Notes'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Capabilities } from 'static/constants'
import { listUniqueById } from 'utils/immutable'

import ContentDelete from 'containers/content_desk_new/contents/content/ContentDelete'
import { IconButton, Menu } from 'containers/themed'
import CapSafe from 'containers/CapSafe'

const SwitchToSearchMenuItem = CapSafe(MenuItem, Capabilities.HAS_NEWS_POOL_MODULE)

const useStyles = makeStyles()(() => ({
  actionIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 8
  },
  actionName: {
    marginLeft: 12
  }
}))

export default function ContentActions({
  content,
  contentDeskContentTypes,
  onEditClick,
  onCloneClick,
  onViewClick,
  onSwitchToSearchPoolClick,
  saveContentStart
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const scheduledForDate = content.get('scheduledFor')
  const campaignName = content.getIn(['ccdCampaign', 'name'])
  const status = content.get('status') || content.get('approvalStatus')
  const showSearchPoolButton = (content.get('plainText') || '').length >= 1200
  const [editDisabled, setEditDisabled] = useState(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)

  const disableEdit = () => setEditDisabled(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)

  useEffect(() => {
    if (status === 'scheduled' && moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 15 && !editDisabled) {
      const interval = setInterval(() => disableEdit(), 5000)

      return () => clearInterval(interval)
    }

    setEditDisabled(false)

    return () => null
  }, [status])

  const handleNotes = () => onEditClick({ id: content.get('id'), openContentNotesDialog: true })
  const handleEdit = () => {
    if (content.get('type').toLowerCase() === 'email') {
      onEditClick({ id: content.get('id'), openEditContentDialog: true })
    } else {
      onEditClick({ id: content.get('id'), openOldEditContentDialog: true })
    }
  }
  const handleViewClick = () => onViewClick(content)

  const currentType = contentDeskContentTypes.find(c => c.get('name').toLowerCase() === content.get('type').toLowerCase())

  const resetLinkedInSpecificFields = () => {
    let linkedInPost = content.get('linkedInPost').delete('createdPostId')

    const mediaFiles = content.get('ccdMediaFiles')

    // this could be an image post or an video post
    // field id needs to be reset to our internal id instead of the linkedIn image ID
    if (mediaFiles.size === 1) {
      linkedInPost = linkedInPost.setIn(['content', 'media', 'id'], String(mediaFiles.first().get('id')))
    }

    // for multi_image
    if (mediaFiles.size > 1) {
      let linkedInImages = mediaFiles.map(selected => (fromJS({
        altText: selected.get('filename'),
        id: String(selected.get('id'))
      })))

      linkedInImages = listUniqueById(linkedInImages)

      const mediaContent = fromJS({
        multiImage: {
          images: linkedInImages
        }
      })

      linkedInPost = linkedInPost.set('content', mediaContent)
    }

    return linkedInPost
  }

  const resetFacebookSpecificFields = () => {
    const facebookPost = content
      .get('facebookPost')
      .delete('createdPostId')
    const mediaAttachments = facebookPost
      .get('mediaAttachments')
      .map(attachment => attachment.delete('fbid'))

    return facebookPost.set('mediaAttachments', mediaAttachments)
  }

  const handleClone = () => {
    let linkedInPost
    let facebookPost

    if (content.get('type').toLowerCase() === 'linkedin') {
      linkedInPost = resetLinkedInSpecificFields()
    }

    if (content.get('type').toLowerCase() === 'facebook') {
      facebookPost = resetFacebookSpecificFields()
    }

    onCloneClick(
      content
        .set('ccdCampaign', fromJS({ value: content.getIn(['ccdCampaign', 'id']), label: campaignName }))
        .set('type', fromJS({ value: currentType.get('id'), label: currentType.get('name') }))
        .set('id', null)
        .set('scheduledFor', null)
        .set('recipients', List())
        .set('linkedInPost', linkedInPost)
        .set('facebookPost', facebookPost)
        .set('title', `${content.get('title')} - duplicate(1)`)
        .set('scheduledJobName', null)
        .set('scheduledEventsJobName', null)
        .set('status', null)
    )

    saveContentStart({ openEditContentDialog: true })
  }

  const renderNoteAction = () => {
    const notes = content.get('notes')

    if (notes.isEmpty()) {
      return (
        <>
          <NotesIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('notes')}
          </div>
        </>
      )
    }

    return (
      <>
        <Badge
          color="secondary"
          badgeContent={notes.size}
        >
          <NotesIcon className={classes.actionIcon} />
        </Badge>
        <div className={classes.actionName}>
          {i18n.get('notes')}
        </div>
      </>
    )
  }

  return (
    <>
      <Menu
        handle={(
          <IconButton
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        )}
      >
        <MenuItem onClick={handleViewClick}>
          <RemoveRedEyeIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('overview_and_kpis')}
          </div>
        </MenuItem>
        <MenuItem
          onClick={handleEdit}
          disabled={status === 'released' || (status === 'scheduled' && editDisabled)}
        >
          <EditIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('edit')}
          </div>
        </MenuItem>
        <MenuItem
          onClick={handleClone}
        >
          <ContentCopyIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('duplicate')}
          </div>
        </MenuItem>
        <MenuItem
          onClick={handleNotes}
        >
          {renderNoteAction()}
        </MenuItem>
        {showSearchPoolButton && (
          <SwitchToSearchMenuItem
            onClick={() => onSwitchToSearchPoolClick({
              field: 'ccdContents',
              value: content
            })}
          >
            <PoolIcon className={classes.actionIcon} />
            <div className={classes.actionName}>
              {i18n.get('switch_to_news_pool_module')}
            </div>
          </SwitchToSearchMenuItem>
        )}
        <ContentDelete
          deleteDisabled={editDisabled}
          title={content.get('title')}
          deleteData={{
            id: content.get('id')
          }}
        />
      </Menu>
    </>
  )
}

ContentActions.propTypes = {
  content: PropTypes.instanceOf(ImmutableMap).isRequired,
  contentDeskContentTypes: PropTypes.instanceOf(List).isRequired,

  onCloneClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSwitchToSearchPoolClick: PropTypes.func.isRequired,
  saveContentStart: PropTypes.func.isRequired
}
