import React from 'react'
import PropTypes from 'prop-types'
import { List, fromJS, Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'

import { makeStyles } from 'tss-react/mui'

import { Dialog, DialogActions, DialogContent, DialogTitle, Avatar, Stack } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'

import { Button, IconButton } from 'containers/themed'

import { mapToOptions } from 'utils/autocomplete'

import { facebookPost as initialFacebookPost } from 'static/facebook_post_config'
import { linkedInPost as initialLinkedInPost } from 'static/linked_in_post_config'

const useStyles = makeStyles()(theme => ({
  dialog: {
    padding: '25px',
    margin: '1em 1em 1em 1em'
  },
  title: {
    color: !theme.isDark && '#404040'
  }
}))

const ContentNewDialog = ({
  open,
  pages,
  profile,
  contentDeskContentTypes,
  socialOnly,
  closeNewContentDialog,
  setContentFormData,
  saveContentStart
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const types = mapToOptions(contentDeskContentTypes)

  const handleBtnClick = type => {
    const filteredType = types.filter(t => t.label.toLowerCase() === type)[0]
    let title

    if (type === 'email') {
      title = `My E-Mail Content (${moment().format()})`
    }

    if (type === 'linkedin') {
      title = `My LinkedIn Content (${moment().format()})`
      const linkedInPost = fromJS(initialLinkedInPost)
        .set('author', profile.get('urn') || '')

      setContentFormData({ key: 'linkedInPost', value: linkedInPost })
    }

    if (type === 'facebook') {
      title = `My Facebook Content (${moment().format()})`
      const defaultPage = pages.filter(p => p.get('instagram') !== true).first() || Map()
      const facebookPost = fromJS(initialFacebookPost)
        .set('pageId', defaultPage.get('id') || '')
        .set('accessToken', defaultPage.get('accessToken') || '')

      setContentFormData({ key: 'facebookPost', value: facebookPost })
    }

    setContentFormData({ key: 'title', value: title })

    if (filteredType) {
      setContentFormData({ key: 'type', value: filteredType })
    }

    if (type === 'email') {
      saveContentStart({ openEditContentDialog: true })
    } else {
      saveContentStart({ openOldEditContentDialog: true })
    }

    closeNewContentDialog()
  }

  const hasLinkedIn = contentDeskContentTypes.find(t => t.get('id') === 2)
  const hasFacebook = contentDeskContentTypes.find(t => t.get('id') === 3)

  return (
    <Dialog
      open={open}
      onClose={() => closeNewContentDialog()}
    >
      <DialogTitle className={classes.title}>
        {i18n.get('content_type')}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          {!socialOnly && (
            <Avatar
              variant="square"
              sx={{ width: 56, height: 56 }}
            >
              <IconButton
                color="primary"
                size="large"
                title={i18n.get('email')}
                onClick={() => handleBtnClick('email')}
              >
                <MailIcon />
              </IconButton>
            </Avatar>
          )}
          {hasLinkedIn && (
            <Avatar
              variant="square"
              sx={{ width: 56, height: 56, bgcolor: '#0072B1' }}
            >
              <IconButton
                size="large"
                title="LinkedIn"
                onClick={() => handleBtnClick('linkedin')}
              >
                <LinkedInIcon sx={{ color: '#FFFFFF' }} />
              </IconButton>
            </Avatar>
          )}
          {hasFacebook && (
            <Avatar
              variant="square"
              sx={{ width: 56, height: 56, bgcolor: '#4267B2' }}
            >
              <IconButton
                size="large"
                title="Facebook"
                onClick={() => handleBtnClick('facebook')}
              >
                <FacebookIcon sx={{ color: '#FFFFFF' }} />
              </IconButton>
            </Avatar>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeNewContentDialog}
        >
          {i18n.get('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContentNewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  pages: PropTypes.instanceOf(List).isRequired,
  profile: PropTypes.instanceOf(Map).isRequired,
  contentDeskContentTypes: PropTypes.instanceOf(List).isRequired,
  socialOnly: PropTypes.bool.isRequired,
  closeNewContentDialog: PropTypes.func.isRequired,
  setContentFormData: PropTypes.func.isRequired,
  saveContentStart: PropTypes.func.isRequired
}

export default ContentNewDialog
