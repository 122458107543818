import React, { useEffect } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import { Alert, AlertTitle, Box, LinearProgress, Grid, Typography } from '@mui/material'
import { red } from '@mui/material/colors'

/* Caution: these components are still in the old directory */
import ContentPreview from 'containers/content_desk/ContentPreview'

import RecipientsList from 'containers/content_desk_new/contents/content_edit_dialog/RecipientsList'
import DistributionLists from 'containers/content_desk_new/contents/content_edit_dialog/DistributionLists'

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '40px 20px 0px 20px'
  },
  limitContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  progress: {
    alignItems: 'center',
    display: 'flex',
    minWidth: '200px'
  },
  progressBar: {
    width: '100%'
  },
  usageLimitLabel: {
    alignSelf: 'center',
    fontSize: '12px',
    opacity: '0.5'
  },
  content: {
    color: theme.palette.grey[600]
  },
  label: {
    fontSize: 16
  },
  previewContainer: {
    display: 'block',
    borderRadius: '10px',
    width: '100%'
  },
  previewInnerContainer: {
    margin: 'auto'
  },
  error: {
    color: theme.palette.error.main
  }
}))

const Overview = ({
  config,
  contentFormData,
  fetchDistributionListDropdownOptionsStart
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const emailsSendLimit = config.getIn(['ccdPlan', 'emailsSendLimit'], 0)
  const emailsSendUsage = config.getIn(['ccdPlanUsage', 'emailsSendUsage'], 0)
  const usageLimitDisplay = `${emailsSendUsage} / ${emailsSendLimit}`
  const percentage = Math.round((100 * emailsSendUsage) / emailsSendLimit)
  const isMax = percentage >= 100
  const showWarning = percentage >= 80

  useEffect(() => {
    fetchDistributionListDropdownOptionsStart({ searchString: '' })
  }, [])

  const fieldIsValid = field => !(
    contentFormData.get(field) === undefined
    || contentFormData.get(field) === null
    || contentFormData.get(field) === ''
  )
  const hasRecipients = !contentFormData.get('recipients').isEmpty()

  return (
    <Box className={classes.container}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box className={classes.limitContainer}>
            <Typography className={classes.label}>
              {i18n.get('sent_emails')}
            </Typography>
            <Box className={classes.progress}>
              <Box className={classes.progressBar}>
                <LinearProgress
                  variant="determinate"
                  value={percentage}
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: isMax && red[500]
                    }
                  }}
                />
              </Box>
            </Box>
            <Typography className={classes.usageLimitLabel}>
              {usageLimitDisplay}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        />
        {showWarning && (
          <Grid
            item
            xs={12}
            md={12}
          >
            <Alert severity="warning">
              <AlertTitle>
                {i18n.get('email_limit_warning_title')}
              </AlertTitle>
              <Box
                dangerouslySetInnerHTML={{
                  __html: i18n.get('email_limit_warning_message', { email: 'sales@pressrelations.de' })
                }}
              />
            </Alert>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
        >
          <Typography
            variant="h6"
            mb={1}
          >
            {i18n.get('info')}
          </Typography>
          <Typography className={fieldIsValid('title') ? classes.content : classes.error}>
            <b>{i18n.get('internal_title')} *:</b> {contentFormData.get('title')}
          </Typography>
          <Typography className={classes.content}>
            <b>{i18n.get('campaign')}:</b> {contentFormData.getIn(['ccdCampaign', 'label'])}
          </Typography>
          <Typography
            variant="h6"
            mb={1}
            mt={2}
          >
            {i18n.get('sender_details')}
          </Typography>

          <Typography className={classes.content}>
            <b>{i18n.get('name')}:</b> {contentFormData.get('senderName')}
          </Typography>

          <Typography className={fieldIsValid('senderMail') ? classes.content : classes.error}>
            <b>{i18n.get('mail')} *:</b> {contentFormData.get('senderMail')}
          </Typography>

          <Typography
            variant="h6"
            mb={1}
            mt={2}
          >
            {i18n.get('email_subject')}
          </Typography>

          <Typography className={fieldIsValid('subject') ? classes.content : classes.error}>
            <b>{i18n.get('subject')} *:</b> {contentFormData.get('subject')}
          </Typography>

          <Typography className={classes.content}>
            <b>{i18n.get('email_pre_header')}:</b> {contentFormData.get('preHeader')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}

        >
          <Typography
            variant="h6"
            className={hasRecipients ? null : classes.error}
            mb={1}
          >
            {i18n.get('recipients')} * ({contentFormData.get('recipients').size})
          </Typography>
          <DistributionLists hideOnEmpty />
          <RecipientsList
            maxRecipients={3}
            withOpenAllLink
            hideOnEmpty
          />

          <Typography
            variant="h6"
            mb={1}
            mt={2}
          >
            {i18n.get('unsubscribe_link')}
          </Typography>

          <Typography className={classes.content}>
            {(contentFormData.get('plainText') || '').includes('%tag_unsubscribe_url%') ? i18n.get('yes') : i18n.get('no')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          alignContent={!((contentFormData.get('body') || '').length > 5000) && 'center'}
        >
          <Box className={classes.previewContainer}>
            <Box className={classes.previewInnerContainer}>
              <Typography
                variant="h6"
                mb={1}
                mt={2}
                className={fieldIsValid('body') ? null : classes.error}
              >
                {`${i18n.get('preview')} *:`}
              </Typography>
              <ContentPreview content={contentFormData} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

Overview.propTypes = {
  config: PropTypes.instanceOf(Map).isRequired,
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  fetchDistributionListDropdownOptionsStart: PropTypes.func.isRequired
}

export default Overview
