import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import { MenuItem } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const useStyles = makeStyles()(() => ({
  actionIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 8
  },
  actionName: {
    marginLeft: 12
  }
}))

const ContentDelete = ({
  deleteDisabled,
  deleteData,
  onOpen,
  setDeleteData
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handleOpen = () => {
    setDeleteData(deleteData)
    onOpen()
  }

  return (
    <MenuItem
      onClick={handleOpen}
      disabled={deleteDisabled}
    >
      <DeleteOutlineIcon className={classes.actionIcon} />
      <div className={classes.actionName}>
        {i18n.get('delete')}
      </div>
    </MenuItem>
  )
}

ContentDelete.propTypes = {
  deleteDisabled: PropTypes.bool.isRequired,
  deleteData: PropTypes.object.isRequired,

  onOpen: PropTypes.func.isRequired,
  setDeleteData: PropTypes.func.isRequired
}

export default ContentDelete
