import {
  put,
  takeEvery,
  all,
  call,
  select,
  take
} from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as Actions from 'actions/email_templates_library'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'

export function* togglePublicTemplates() {
  const showPublicTemplates = yield select(Selectors.getETLibraryShowPublic)

  yield put(Actions.resetTemplatesModule())
  yield put(Actions.resetTemplateFilters())
  yield put(Actions.fetchTemplatesStart({ shared: showPublicTemplates }))
}

export function* fetchTemplatesStart({ payload: { shared, released } }) {
  const i18n = yield select(Selectors.getI18n)
  const filters = yield select(Selectors.getETLibraryFilter)

  const filter = [
    { field: 'shared', value: shared }
  ]

  if (released) {
    filter.push({ field: 'released', value: true })
  }

  const requestBody = {
    exact_filter: filter,
    page: filters.get('page'),
    limit: filters.get('limit'),
    sort_by: filters.get('sortBy'),
    sort_order: filters.get('sortOrder')
  }

  try {
    const result = yield call(Api.fetchEmailTemplates, requestBody)

    yield put(Actions.fetchTemplatesSuccess({ shared, result }))
  } catch (error) {
    yield put(Actions.fetchTemplatesError())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* fetchReleasedTemplates() {
  try {
    yield put(Actions.fetchTemplatesStart({ shared: false, released: true }))
    yield take(Actions.fetchTemplatesSuccess)
    yield put(Actions.fetchTemplatesStart({ shared: true, released: true }))
    yield take(Actions.fetchTemplatesSuccess)
    yield put(Actions.fetchReleasedTemplatesSuccess())
  } catch (error) {
    yield put(Actions.fetchReleasedTemplatesError())
  }
}

export function* watchfetchReleasedTemplates() {
  yield takeEvery(Actions.fetchReleasedTemplatesStart, fetchReleasedTemplates)
}

export function* watchTogglePublicTemplates() {
  yield takeEvery(Actions.togglePublicTemplates, togglePublicTemplates)
}

export function* watchFetchTemplateStart() {
  yield takeEvery(Actions.fetchTemplatesStart, fetchTemplatesStart)
}

export default function* searchSaga() {
  yield all([
    watchFetchTemplateStart(),
    watchTogglePublicTemplates(),
    watchfetchReleasedTemplates()
  ])
}
