/* global document */
import { put, call, select, takeEvery, takeLatest, all, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import decamelizeKeysDeep from 'decamelize-keys-deep'

import * as Actions from 'actions/app'
import { configRequestStart } from 'actions/config'
// import * as GaActions from 'actions/ga'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'
import getNewViewVersion from 'api/version'
import * as UserActions from 'actions/user'
import { navigate, backToLogin, redirectAuthenticated } from 'actions/navigation'
import { logException } from 'utils/error'
import * as CookieHelper from 'utils/cookieHelper'
import { CookieNames } from 'static/constants'
import { uploadFile, downloadBlob } from 'utils/sagas'
import { downloadCanvas } from 'utils/downloadSvg'
import { canvasToPdf, htmlToPdf } from 'utils/jsPdf'

export function* appMessage(text, success) {
  yield put(Actions.toggleSuccess(success === undefined ? true : success))
  yield put(Actions.toggleAppMessage(true))
  yield delay(250)
  yield put(Actions.setAppMessage(text))
  yield delay(750)
  yield put(Actions.toggleAppMessage(false))
  yield put(Actions.setAppMessage(null))
}

export function* appSnackbarMessage(text, variant, preventDuplicate) {
  const prevDuplicate = preventDuplicate || false
  yield put(Actions.triggerAppSnackbarMessage({ text, variant, preventDuplicate: prevDuplicate }))
}

// CAUTION: Also used in sagas/dashboard
export function* newsradarSwitch(id) {
  yield put(Actions.resetState())
  yield put(Actions.setActiveNewsradar(id))
  yield put(configRequestStart())
}

export function* switchNewsradar(action) {
  const isSelectedDashboard = yield select(Selectors.isSelectedDashboard)

  if (isSelectedDashboard) {
    yield put(navigate('/app/dashboard'))
  }

  yield* newsradarSwitch(action.payload)
}

export function* setActiveNewsradar({ payload: newsradarId }) {
  yield call(CookieHelper.saveCookie, CookieNames.NEWSRADAR, newsradarId)
}

export function* genericSuccessMessage() {
  const i18n = yield select(Selectors.getI18n)
  yield call(appMessage, i18n.get('success'), true)
}

export function* genericErrorMessage() {
  const i18n = yield select(Selectors.getI18n)
  yield call(appMessage, i18n.get('unknown_error'), false)
}

export function* showAppMessage({ payload: { text, success } }) {
  yield call(appMessage, text, success)
}

export function* showAppErrorMessage({ payload: text }) {
  yield call(appMessage, text, false)
}

export function* showAppSuccessMessage({ payload: text }) {
  yield call(appMessage, text, true)
}

export function* showAppSnackbarMessage({ payload: { text, variant, preventDuplicate } }) {
  yield call(appSnackbarMessage, text, variant, preventDuplicate)
}

export function* logout() {
  try {
    const accessToken = yield select(Selectors.getAccessToken)
    const result = yield call(Api.logout, { access_token: accessToken })

    yield put(Actions.logoutRequestSuccess(result))
    yield call(CookieHelper.deleteSessionCookie)
  } catch (error) {
    yield put(Actions.logoutRequestError(error))
    yield put(Actions.exception(error))
  }
}

export function* login({ payload: { username, password, secret } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const newsradarId = yield select(Selectors.getNewsradarId)

    if (!(username && password) && !secret) {
      yield put(Actions.loginRequestAborted(i18n.get('login_provide_both')))
    } else {
      const { user, newsradarId: newNewsradarId } = yield call(Api.login, { username, password, secret, newsradar_id: newsradarId })

      if (newNewsradarId !== newsradarId) {
        yield put(Actions.setActiveNewsradar(newNewsradarId))
      }

      yield put(Actions.loginRequestSuccess(user))
      yield call(CookieHelper.deleteSessionCookie)
    }
  } catch (error) {
    let msg = i18n.get('login_invalid')

    if (error.response) {
      if (error.response.statusCode === 422) {
        msg = i18n.get('login_no_newsradar')
      }

      if (error.response.statusCode === 405) {
        msg = i18n.get('login_method_not_allowed')
      }

      if ([401, 422, 405].indexOf(error.response.statusCode) === -1) {
        yield put(Actions.exception(error))
      }
    } else {
      msg = i18n.get('login_unknown_error')
    }

    yield call(CookieHelper.deleteSessionCookie)
    yield put(UserActions.reset())

    if (secret) {
      msg = i18n.get('login_link_invalid')
      yield put(backToLogin())
    }

    yield put(Actions.loginRequestError(msg))
  }
}

export function* loginRequestSuccess({ payload: user }) {
  if (!user.newsradars.length) {
    const i18n = yield select(Selectors.getI18n)
    yield put(Actions.loginRequestError(i18n.get('login_no_newsradar')))
  } else {
    let newsradarId = user.newsradars[0].id
    const currentNewsradarId = yield select(Selectors.getNewsradarId)

    if (currentNewsradarId && user.newsradars.map(v => v.id).indexOf(parseInt(currentNewsradarId, 10)) !== -1) {
      newsradarId = currentNewsradarId
    }

    yield put(Actions.setActiveNewsradar(newsradarId))
    yield put(UserActions.setUser(user))
    yield put(redirectAuthenticated())
  }
}

export function* checkBffVersion({ payload: newBffVersion }) {
  const bffVersion = yield select(Selectors.getBffVersion)
  const autoRefresh = yield select(Selectors.getDashboardAutoRefresh)

  if (!bffVersion) {
    yield put(Actions.setBffVersion(newBffVersion))
  } else if (bffVersion !== newBffVersion && !autoRefresh) {
    yield put(Actions.setBffVersion(newBffVersion))
    yield put(Actions.forceReload())
  }
}

export function* checkViewVersion() {
  try {
    for (; ;) {
      const viewVersion = yield select(Selectors.getViewVersion)
      const { version: newViewVersion } = yield call(getNewViewVersion)
      const autoRefresh = yield select(Selectors.getDashboardAutoRefresh)

      if (!viewVersion) {
        yield put(Actions.setViewVersion(newViewVersion))
      } else if (viewVersion !== newViewVersion && !autoRefresh) {
        yield put(Actions.setViewVersion(newViewVersion))
        yield put(Actions.forceReload())
      }

      yield delay(60000)
    }
  } catch (e) { // eslint-disable-line
  } finally {
    yield delay(60000)
    yield put(Actions.checkViewVersion())
  }
}

export function* exception({ payload: error }) {
  if (!error.response || (error.response && error.response.statusCode !== 401)) {
    yield call(logException, error)
  } else {
    const i18n = yield select(Selectors.getI18n)
    yield put(Actions.loginRequestError(i18n.get('not_logged_in')))
    yield put(UserActions.reset())
  }
}

export function* uploadGeneratedFile(data, filename, mimeType) {
  try {
    yield put(Actions.uploadGeneratedFileStart())

    const result = yield call(uploadFile, data, filename, mimeType, moment().add(1, 'hours').format())

    yield put(Actions.uploadGeneratedFileSuccess(result))
  } catch (error) {
    yield put(Actions.uploadGeneratedFileError(error))
    yield put(Actions.genericErrorMessage())
    yield put(Actions.exception(error))
  }
}

export function* sso({ payload: { type, data } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.sso, { type, data: decamelizeKeysDeep(data) })

    if (result.status === 403) {
      yield put(Actions.loginRequestError(
        i18n.get(
          'sso_user_does_not_exist',
          {
            email: `<b>${result.email}</b>`
          }
        )
      ))
    } else {
      yield put(Actions.loginRequestSuccess(result))
    }
  } catch (error) {
    yield put(Actions.ssoRequestError(i18n.get('unknown_error')))
    yield put(Actions.genericErrorMessage())
    yield put(Actions.exception(error))
  }
}

export function* downloadElementAsImage({ payload: { id, filename, selector, opts } }) {
  try {
    const result = yield call(downloadCanvas, id, selector, opts)

    yield call(downloadBlob, result.blob, filename, 'image/png')
  } catch (error) {
    yield put(Actions.exception(error))
    yield put(Actions.genericErrorMessage())
  } finally {
    yield put(Actions.downloadElementAsImageDone())
  }
}

export function* downloadElementAsPdf({ payload: { id, filename, selector, opts, asHtml } }) {
  try {
    if (asHtml) {
      const html = document.getElementById(id).innerHTML

      yield call(htmlToPdf, html, filename)
    } else {
      const options = { ...(opts || {}), ...{ mimeType: 'image/jpeg' } }

      const { canvas, dataUrl } = yield call(downloadCanvas, id, selector, options)

      yield call(canvasToPdf, canvas, dataUrl, filename)
    }
  } catch (error) {
    yield put(Actions.exception(error))
    yield put(Actions.genericErrorMessage())
  } finally {
    yield put(Actions.downloadElementAsPdfDone())
  }
}

export function* watchSwitchNewsradar() {
  yield takeEvery(Actions.switchNewsradar, switchNewsradar)
}

export function* watchSetActiveNewsradar() {
  yield takeEvery(Actions.setActiveNewsradar, setActiveNewsradar)
}

export function* watchGenericSuccess() {
  yield takeEvery(Actions.genericSuccessMessage, genericSuccessMessage)
}

export function* watchShowAppMessage() {
  yield takeLatest(Actions.showAppMessage, showAppMessage)
}

export function* watchShowAppErrorMessage() {
  yield takeLatest(Actions.showAppErrorMessage, showAppErrorMessage)
}

export function* watchShowAppSuccessMessage() {
  yield takeLatest(Actions.showAppSuccessMessage, showAppSuccessMessage)
}

export function* watchShowAppSnackbarMessage() {
  yield takeLatest(Actions.showAppSnackbarMessage, showAppSnackbarMessage)
}

export function* watchGenericError() {
  yield takeEvery(Actions.genericErrorMessage, genericErrorMessage)
}

export function* watchLogout() {
  yield takeEvery(Actions.logoutRequestStart, logout)
}

export function* watchLogin() {
  yield takeEvery(Actions.loginRequestStart, login)
}

export function* watchLoginSuccess() {
  yield takeEvery(Actions.loginRequestSuccess, loginRequestSuccess)
}

export function* watchCheckBffVersion() {
  yield takeEvery(Actions.checkBffVersion, checkBffVersion)
}

export function* watchCheckViewVersion() {
  yield takeEvery(Actions.checkViewVersion, checkViewVersion)
}

export function* watchException() {
  yield takeEvery(Actions.exception, exception)
}

export function* watchSso() {
  yield takeEvery(Actions.ssoRequestStart, sso)
}

export function* watchDownloadElementAsImage() {
  yield takeEvery(Actions.downloadElementAsImage, downloadElementAsImage)
}

export function* watchDownloadElementAsPdf() {
  yield takeEvery(Actions.downloadElementAsPdf, downloadElementAsPdf)
}

export default function* appSaga() {
  yield all([
    watchSwitchNewsradar(),
    watchSetActiveNewsradar(),
    watchGenericSuccess(),
    watchShowAppMessage(),
    watchShowAppErrorMessage(),
    watchShowAppSuccessMessage(),
    watchShowAppSnackbarMessage(),
    watchGenericError(),
    watchLogout(),
    watchLogin(),
    watchLoginSuccess(),
    watchCheckBffVersion(),
    watchCheckViewVersion(),
    watchException(),
    watchSso(),
    watchDownloadElementAsImage(),
    watchDownloadElementAsPdf()
  ])
}
